<template>
  <div class="text-center w-100">
    <ACProvider access="user_manage">
      <div>
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-btn-help"
          class="mb-2 v-btn--aside"
          @click="$root.$emit('openUserForm')"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-user-plus
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Добавить<br>
          пользоват.
        </div>
      </div>
    </ACProvider>
  </div>
</template>

<script>
import * as names from '@/plugins/vue-router/constants';
import ACProvider from '@/components/acl/ACProvider';

export default {
  name: 'AppUserAsideRight',
  components: { ACProvider },
  data: () => ({
    names,
  }),
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
