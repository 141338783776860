import { mapGetters } from 'vuex';

export default {
  name: 'ACProvider',
  computed: {
    ...mapGetters('acl', ['can']),
  },
  props: {
    access: {
      type: String,
      required: true,
    },
  },
  render() {
    return this.can(this.access) ? this.$slots.default : null;
  },
};
